import { Link } from "react-router-dom";
// import $ from "jquery";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { UserNo, UserOut } from "../redux/actions/productActions";
// import { API_URL } from "../apis/fakeStoreApi";
// import axios from "axios";

export const Header = () => {
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  const [Show, setShow] = useState("false");
  const user = useSelector((state) => state.user.payload);
  const dispatch = useDispatch();
  ////console.log(user, "user data");
  useEffect(() => {
    if (user !== undefined) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [user]);

  // const OpenPopUp = () => {
  //   $("#verificationModal").addClass("show");
  //   $("#verificationModal").attr("style", "display: block");
  //   $("body").append("<div className='modal-backdrop fade show'></div>");
  // };

  const LogOut = () => {
    dispatch(UserOut());
    const dataPhone = {
      phone: "",
      isVerified: false,
    };

    dispatch(UserNo(dataPhone));
    localStorage.setItem("user", []);
    localStorage.setItem("user_phone", []);
  };

  // const [AddressInput, setInput] = useState({});

  // const hadleInputChange = (e) => {
  //   var Value = e.target.value;
  //   var Id = e.target.id;
  //   setInput({
  //     ...AddressInput,
  //     [Id]: Value,
  //   });
  // };

  // const SendOtp = (event) => {
  //   event.preventDefault();
  //   var formData = new FormData();
  //   formData.append("postData", JSON.stringify(AddressInput));
  //   ////console.log(AddressInput);
  //   const config = {
  //     headers: { "content-type": "multipart/form-data" },
  //   };

  //   var url = API_URL + "sendotp";
  //   axios
  //     .post(url, formData, config)
  //     .then((response) => {
  //       ////console.log(response);
  //     })
  //     .catch((error) => {
  //       ////console.log(error);
  //     });
  // };

  const hoverStyles = {
    color: 'white',
  };

  return (
    <header className="sticky-top">
      {/* Modile View Start fixed-top*/}
      <div className="border-bottom p-2 bg-light d-none mobile-nav">
        <div className="title d-flex align-items-center">
          <Link className="navbar-brand mr-0 w-75" to="/">
            <img
              className="img-fluid"
              src="./assets/img/repair_bro_logo.png"
              // src="assets/img/mps_logo.jpg"
              alt="Logo"
              style={{ width: "35%" }}
            />
          </Link>
          <div className="ml-auto d-flex m-0">
            {Show ? (
              <div className="dropdown rounded-pill p-2">
                <Link
                  to="#"
                  className="text-dark dropdown-toggle not-drop justify-content-center"
                  style={{ height: 35, width: 35 }}
                  id="dropdownMenuNotification"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <center>
                    <i
                      className="fas fa-user text-center"
                      style={{ fontSize: 15 }}></i>
                  </center>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right p-0 osahan-notifications-main"
                  aria-labelledby="dropdownMenuNotification"
                  style={{ minWidth: 150 }}>
                  <Link to="/my_orders">
                    <div className="osahan-notifications bg-info  text-center">
                      My Requests
                    </div>
                  </Link>
                  <Link
                    to="/"
                    onClick={() => {
                      LogOut();
                    }}>
                    <div className="osahan-notifications bg-info  text-center">
                      LogOut
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <Link
                // style={{ backgroundColor: "#e3181b" }}
                to="#"
                data-toggle="modal"
                data-target="#verificationModal"
                // onClick={() => {
                //   OpenPopUp();
                // }}
                className=" icofont-size">
                <p className="mb-0 login-mobile-btn on-hover-white px-3">
                  {" "}
                  <span style={{ fontSize: "12px" }} className="on-hover-white">Login </span>{" "}
                </p>
              </Link>
            )}
          </div>
        </div>

        <div className="text-decoration-none" style={{ borderRadius: "4px" }}>
          <div className="input-group rounded overflow-hidden justify-content-between align-items-center p-2">
            <div className="row d-flex justify-content-between align-items-center">
              <div>
                <Link to="/" className="text-dark">
                  Home
                </Link>
                <Link to="/product_list" className="mx-2 text-dark">
                  Services
                </Link>
                <Link to="/cart_page" className="text-dark">
                  Cart
                </Link>
                {Show === true ? (
                  <Link to="/my_orders" className="ml-2 text-dark">
                    My Request
                  </Link>
                ) : null}
              </div>
              <div className="d-flex ml-4">
                <a
                  href="https://api.whatsapp.com/send?phone=918555824915"
                  target="_blank"
                  className="text-light"
                  rel="noreferrer">
                  <img src="./assets/img/whatsapp.png" alt="" width="20px" />
                </a>
                <div className="ml-2 list-unstyled form-inline mb-0 d-flex flex-row-reverse bd-highlight align-items-center">
                  <a href="tel:8555824915" className="text-dark">
                    <i className="fas fa-phone-alt text-success mr-2" style={{ fontSize: 12 }}></i>
                    {/* | +91 832 862 0888 */}
                  </a>
                </div>
              </div>

              {/* <button className="border-0 btn btn-outline-secondary text-success bg-white">
                <i className="icofont-search"></i>
              </button> */}
            </div>
            {/* <input
              type="text"
              className="shadow-none border-0 form-control pl-0"
              placeholder="Search for Products.."
              aria-label=""
              aria-describedby="basic-addon1"
            /> */}
          </div>
        </div>


      </div>
      {/* Modile View Ends */}
      <div className="bg-white shadow-sm osahan-main-nav">
        <nav className="navbar navbar-expand-lg navbar-light bg-white osahan-header py-0 container">
          <Link className="navbar-brand mr-0 my-2" to="/">
            <img
              className="img-fluid"
              src="./assets/img/repair_bro_logo.png"
              alt="Logo"
              style={{ width: "30%" }}
            />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="ml-5 d-flex align-items-center">
            {/* <div className="input-group mr-sm-2 col-lg-12">
              <input
                type="text"
                className="form-control"
                id="inlineFormInputGroupUsername2"
                placeholder="Search for Products.."
              />
              <div className="input-group-prepend">
                <div className="btn btn-success rounded-right">
                  <i className="icofont-search"></i>
                </div>
              </div>
            </div> */}
          </div>

          <div className="ml-auto d-flex  align-items-center">
            {Show ? (
              <div
                className="dropdown details-btn-drop rounded-pill p-2"
                style={{}}>
                <Link
                  to="#"
                  className="text-dark dropdown-toggle not-drop justify-content-center"
                  style={{ height: 35, width: 35 }}
                  id="dropdownMenuNotification"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false">
                  <center>
                    <i
                      className="fas fa-user text-center"
                      style={{ fontSize: 25 }}></i>
                  </center>
                </Link>
                <div
                  className="dropdown-menu dropdown-menu-right p-0 osahan-notifications-main"
                  aria-labelledby="dropdownMenuNotification"
                  style={{ minWidth: 150 }}>
                  <Link to="/my_orders">
                    <div className="osahan-notifications bg-info  text-center">
                      My Requests
                    </div>
                  </Link>
                  <Link
                    to="/"
                    onClick={() => {
                      LogOut();
                    }}>
                    <div className="osahan-notifications bg-info  text-center">
                      LogOut
                    </div>
                  </Link>
                </div>
              </div>
            ) : (
              <Link
                to="#"
                data-toggle="modal"
                data-target="#verificationModal"
                // onClick={() => {
                //   OpenPopUp();
                // }}
                className="mr-2  p-2 px-4 icofont-size loginButton">
                <p className="mb-0" style={{ fontSize: "13px", ':hover': hoverStyles, }}>
                  {" "}
                  <span className="on-hover-white">Login</span>
                  {" "}
                </p>
              </Link>
            )}
            <div className="dropdown ml-3">
              <div
                className=" dropdown-toggle d-flex align-items-center osahan-location-drop "
                id="navbarDropdown"
                role="button"
                data-toggle="modal"
                data-target="#SelectCar"
                aria-haspopup="true"
                aria-expanded="false">
                <div className="px-4  loginButton on-hover-white">
                  {carSelected.isCarSelected ? (
                    <>
                      <p className="text-danger mb-0 mt-2 on-hover-white" style={{ fontSize: "12px" }}>
                        <u className="on-hover-white">Selected Car</u>
                      </p>
                      <h6 style={{ fontSize: "12px" }} className="on-hover-white">
                        {carSelected.Company}, {carSelected.Model}
                      </h6>
                    </>
                  ) : (
                    <p className="p-2 mb-0">Select a Car</p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </nav>
        <div className="navbar navbar-expand-lg bg-color-head">
          <div className="container menu-bar d-flex align-items-center px-0">
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <ul className="list-unstyled form-inline mb-0">
                <li className="nav-item active">
                  <Link
                    className="nav-link text-white"
                    style={{ fontSize: 16, fontWeight: 500 }}
                    to="/">
                    Home <span className="sr-only">(current)</span>
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-white
                    "
                    to="/product_list"
                    role="button"
                    style={{ fontSize: 16, fontWeight: 500 }}>
                    Services
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className="nav-link text-white"
                    to="/cart_page"
                    role="button"
                    style={{ fontSize: 16, fontWeight: 500 }}>
                    Cart
                  </Link>
                </li>

                {Show === true ? (
                  <li className="nav-item">
                    <Link
                      className="nav-link text-white
                      "
                      to="/my_orders"
                      role="button"
                      style={{ fontSize: 16, fontWeight: 500 }}>
                      My Request
                    </Link>
                  </li>
                ) : null}
              </ul>
            </div>
            <div className="list-unstyled form-inline mb-0 ml-auto d-flex align-items-center">
              {/* <form action="" onSubmit={SendOtp}>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  onChange={(e) => {
                    hadleInputChange(e);
                  }}
                />
                <button type="submit">Check</button>
              </form> */}

              {/* <Link to="#" className="text-dark px-3 py-2">
                WhatsApp
              </Link> */}
              <a
                href="https://api.whatsapp.com/send?phone=918555824915"
                target="_blank"
                className="text-light py-2"
                rel="noreferrer">
                <img src="./assets/img/whatsapp.png" alt="" width="30px" />
              </a>
              <a href="tel:8555824915" className="text-dark py-2 px-3">
                <i className="fas fa-phone-alt text-success " style={{ fontSize: 20 }}></i>
                {/* | +91 832 862 0888 */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
