import { Link } from "react-router-dom";

export const OrderPlaced = () => {
  return (
    <div style={{ overflowX: "hidden", }}>
      <div className="row d-flex justify-content-center">
        <div className="col-md-6" style={{ marginBottom: 50 }}>
          <div className="p-2 text-center">
            <i className="icofont-check-circled display-1 text-success"></i>
            <h5 className="text-success">
              Your request has been successfully Placed 🎉
            </h5>
            <p className="text-dark">
              Check your request status in{" "}
              <Link
                to="#"
                className="font-weight-bold text-decoration-none text-dark">
                My Requests
              </Link>{" "}
              about next steps information.
            </p>
          </div>
          <div className="bg-white rounded p-3 text-center">
            <h6 className="font-weight-bold mb-2">Preparing your order</h6>
            <p className="small text-muted">
              Our team will update your request soon.
            </p>
            <Link
              to="/my_orders"
              className="btn rounded btn-success btn-block">
              Track My Request
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
