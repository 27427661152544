import axios from "axios";
import { useSelector } from "react-redux";
import { AddressComponent } from "../containers/address-component";
// import { CartPageItems } from "../containers/cart-page-item";
import { CartPageItems } from "../containers/cart-page-item";
import { DeliveryComponent } from "../containers/delivery-component";
import { SideTotalBill } from "../containers/side-total-bill";
import $ from "jquery";
import { useEffect, useState } from "react";
import { ModalAddAddressComponent } from "../containers/modal-addaddress-component";
import { ServiceType } from "../containers/service-type";
import { API_URL } from "../apis/fakeStoreApi";
import { useHistory } from "react-router";
import { EmptyCart } from "../redux/actions/productActions";
import { useDispatch } from "react-redux";

export const CartPage = () => {
  const [step, setstep] = useState(1);

  const nextStep = () => {
    setstep(step + 1);
  };

  // const prevStep = () => {
  //   setstep(step - 1);
  // };

  let total_pay = 0;
  let total_discount = 0;
  let total_count = 0;

  const [ShowSchedule, setShowSchedule] = useState(true);
  const user = useSelector((state) => state.user.payload);
  const [, setredirect] = useState(false);
  const UserPhone = useSelector((state) => state.phone);
  const localPhone = localStorage.getItem("user_phone")
    ? JSON.parse(localStorage.getItem("user_phone"), true)
    : null;

  const dispatch = useDispatch();
  const CarSelected = useSelector((state) => state.carSelected.carSelected);

  const CartItems = useSelector((state) => state.cartItems.cartProducts);

  CartItems?.map((Item) => {
    const { product_price, product_discount } = Item;
    total_discount += parseInt(
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );
    total_pay += parseInt(
      parseInt(product_price) -
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );
    total_count += 1;
    return null;
  });

  console.log('CartItemsCartItems', CartItems)

  useEffect(() => {
    if (localPhone && localPhone.isVerified) {
      $(".modal-backdrop").remove();
      $("#verificationModal").removeClass("show");
      $("#verificationModal").addClass("hidden");
      $("#verificationModal").attr("style", "display: none");
      $("body").removeClass("modal-open");
      $("body").removeAttr("style");
    } else {
      $("#verificationModal").addClass("show");
      $("#verificationModal").attr("style", "display: block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    }
  }, [UserPhone, localPhone]);

  const [ServiceTypeOption, setServiceTypeOption] = useState("");
  const [AddressOption, setAddressOption] = useState("");
  const [deliveryCharges, setdeliveryCharges] = useState(0);
  useEffect(() => {
    if (
      ServiceTypeOption === "pickupdrop" ||
      ServiceTypeOption === "doorstep"
    ) {
      setdeliveryCharges(500); // eslint-disable-next-line
    } else {
      setdeliveryCharges(0); // eslint-disable-next-line
    }
  }, [ServiceTypeOption]);
  const onServiceChange = (item) => {
    setServiceTypeOption(item.target.value);
  };

  const onAddressSet = (item) => {
    setAddressOption(item);
  };


  CartItems?.map((Item) => {
    total_count += 1;
    return <></>;
  });

  // const date = new Date();
  const [AddressInput, setAddressInput] = useState({
    date: '',
    time: '',
  });

  const [SelectedDate, setSelectedDate] = useState("");
  const [SelectedTime, setSelectedTime] = useState("");
  console.log('SelectedDate, SelectedTime', SelectedDate, SelectedTime)
  const hadleInputOnClickDate = (e) => {
    setSelectedDate(e);
    var Id = e.target.id;
    // if (Id === "date") {
    //   setSelectedDate(Value);
    // }
    setAddressInput({
      ...AddressInput,
      [Id]: SelectedDate,
    });
  };

  const hadleInputOnClickTime = (e) => {
    setSelectedTime(e);
    var Id = e.target.id;

    // if (Id === "date") {
    //   setSelectedDate(Value);
    // }
    setAddressInput({
      ...AddressInput,
      [Id]: SelectedTime,
    });
  };
  console.log('AddressInput, formData', AddressInput)

  const ScheduleService = async (event) => {
    console.log('ScheduleService')
    event.preventDefault();
    var formData = new FormData();

    const data = {
      time: SelectedTime,
      date: SelectedDate
    }

    formData.append("postData", JSON.stringify(data));
    formData.append("user", JSON.stringify(user));
    formData.append("CartItems", JSON.stringify(CartItems));
    formData.append("CarSelected", JSON.stringify(CarSelected.CarSelected));
    formData.append("ServiceTypeOption", JSON.stringify(ServiceTypeOption));

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    for (var value of formData.values()) {
      console.log(value)
    }

    // console.log("formData", formData.getAll())
    // console.log('Axios Response formData ', formData)
    var url = API_URL + "place_order";
    if (ServiceTypeOption === 'doorstep') {
      if (data.date === '' || data.time === '') {
        console.log(data.date, data.time)
        return;
      }
    }
    axios
      .post(url, formData, config)
      .then((response) => {
        console.log(response.data, "Axios Response");
        if (response.data.response === "inserted") {
          SubmitDataOk();
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const history = useHistory();

  const SubmitDataOk = () => {
    dispatch(EmptyCart());
    history.push("/order_placed");
    setredirect(true);
  };

  switch (step) {
    case 1:
      return (
        <div>
          <div className="container rounded mt-md-4 mt-lg-4">
            <section className="osahan-main-body" style={{ overflowX: "hidden" }}>
              <div className="">
                <h5 className="mb-0 my-2">Cart</h5>
                <div className="row" style={{ marginBottom: 10 }}>

                  <div className="col-lg-8 mb-3">
                    <CartPageItems />
                    <div className="d-flex justify-content-center py-md-3 py-lg-3">
                      {/* <button type="submit" className="btn form-back-btn" onClick={prevStep}>Back</button> */}

                      {
                        localStorage.getItem("user") ?
                          <>
                            {
                              CartItems.length > 0 ?
                                <button type="submit"
                                  className="py-3 btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                                  onClick={nextStep}>Click here to select service type</button>
                                :
                                <button
                                  style={{ padding: "12px" }}
                                  type="submit"
                                  className="btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2">Please add cart items</button>
                            }
                          </>
                          :
                          <button
                            data-toggle="modal"
                            data-target="#verificationModal"
                            type="submit"
                            className="py-3 btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2">Click here to select service type</button>
                      }
                    </div>

                    {/* <div className="d-flex justify-content-end mt-md-3 mt-lg-3">
                    {ShowSchedule ?
                      <button
                        onClick={ScheduleService}
                        to="#"
                        className="btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                        // d-md-none d-lg-none
                        type="submit"
                        data-toggle="collapse"
                        data-target="#collapsefour"
                        aria-expanded="true"
                        aria-controls="collapsefour"
                      >
                        Total Submit {total_pay}

                      </button> : null}
                  </div> */}
                    {/* <button className="">Submit Total</button> */}
                  </div>
                  <div className="col-lg-4 pl-0 mb-3">
                    <SideTotalBill deliveryCharges={deliveryCharges} />
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      );

    case 2:
      return (
        <div className="container rounded mt-md-4 mt-lg-4">
          <section className="osahan-main-body" style={{ overflowX: "hidden" }}>
            <div className="">
              <h5 className="mb-0 my-2">Cart</h5>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-lg-8">
                  { }
                  {total_count > 0 && user ? (
                    <>
                      <ServiceType onServiceChange={onServiceChange} />
                      {/* {ServiceTypeOption !== "" ? (
                          <>
                            <AddressComponent
                              onAddressSet={onAddressSet}
                              ServiceTypeOption={ServiceTypeOption}
                            />
                            <ModalAddAddressComponent />
                            {AddressOption === true ? (
                              <DeliveryComponent
                                ServiceTypeOption={ServiceTypeOption}
                                hadleInputOnClickDate={hadleInputOnClickDate}
                                hadleInputOnClickTime={hadleInputOnClickTime}
                                ScheduleService={ScheduleService}
                                SelectedDate={SelectedDate}
                                SelectedTime={SelectedTime}
                                setShowSchedule={setShowSchedule}
                              />
                            ) : null}
                          </>
                        ) : null} */}
                    </>
                  ) : null}


                  <div className="d-flex justify-content-center py-md-3 py-lg-3">
                    {/* <button type="submit" className="btn btn-outline-danger form-back-btn" onClick={prevStep}>Back</button> */}

                    {
                      ServiceTypeOption ?
                        <button type="submit"

                          className="py-3 btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2" onClick={nextStep}>
                          Click here to select address
                        </button>
                        :
                        <button type="submit"

                          className="py-3 btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2" disabled>
                          Click here to select address
                        </button>
                    }

                  </div>

                  {/* <div className="d-flex justify-content-end mt-md-3 mt-lg-3">
                  {ShowSchedule ?
                    <button
                      onClick={ScheduleService}
                      to="#"
                      className="btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                      // d-md-none d-lg-none
                      type="submit"
                      data-toggle="collapse"
                      data-target="#collapsefour"
                      aria-expanded="true"
                      aria-controls="collapsefour"
                    >
                      Total Submit {total_pay}

                    </button> : null}
                </div> */}
                  {/* <button className="">Submit Total</button> */}
                </div>
                <div className="col-lg-4 pl-0 mb-3">
                  <SideTotalBill deliveryCharges={deliveryCharges} />
                </div>
              </div>
            </div>
          </section>
        </div>
      );

    case 3:
      return (
        <div className="container rounded mt-md-4 mt-lg-4">
          <section className="osahan-main-body" style={{ overflowX: "hidden" }}>
            <div className="">
              <h5 className="mb-0 my-2">Cart</h5>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-lg-8">
                  <ModalAddAddressComponent />

                  {ServiceTypeOption !== "" ? (
                    <>
                      <AddressComponent
                        onAddressSet={onAddressSet}
                        ServiceTypeOption={ServiceTypeOption}
                      />

                    </>
                  ) : null}
                  {/* <AddressComponent
                    onAddressSet={onAddressSet}
                    ServiceTypeOption={ServiceTypeOption}
                  /> */}

                  <div className="d-flex justify-content-center py-md-3 py-lg-3">
                    {/* <button type="submit" className="btn btn-outline-danger form-back-btn" onClick={prevStep}>Back</button> */}

                    <button type="submit"
                      className="py-3 btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"

                      onClick={nextStep}>
                      Click here to continue
                    </button>
                  </div>

                  {/* <div className="d-flex justify-content-end mt-md-3 mt-lg-3">
                  {ShowSchedule ?
                    <button
                      onClick={ScheduleService}
                      to="#"
                      className="btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                      // d-md-none d-lg-none
                      type="submit"
                      data-toggle="collapse"
                      data-target="#collapsefour"
                      aria-expanded="true"
                      aria-controls="collapsefour"
                    >
                      Total Submit {total_pay}

                    </button> : null}
                </div> */}
                  {/* <button className="">Submit Total</button> */}
                </div>

                <div className="col-lg-4 pl-0 mb-3">
                  <SideTotalBill deliveryCharges={deliveryCharges} />
                </div>
              </div>
            </div>
          </section>
        </div>
      );

    case 4:
      return (
        <div className="container rounded mt-md-4 mt-lg-4">
          <section className="osahan-main-body" style={{ overflowX: "hidden" }}>
            <div className="">
              <h5 className="mb-0 my-2">Cart</h5>
              <div className="row" style={{ marginBottom: 10 }}>
                <div className="col-lg-8">
                  {AddressOption === true ? (
                    <DeliveryComponent
                      ServiceTypeOption={ServiceTypeOption}
                      hadleInputOnClickDate={hadleInputOnClickDate}
                      hadleInputOnClickTime={hadleInputOnClickTime}
                      ScheduleService={ScheduleService}
                      SelectedDate={SelectedDate}
                      SelectedTime={SelectedTime}
                      setShowSchedule={setShowSchedule}
                    />
                  ) : null}

                  {
                    step === 4 && ShowSchedule && AddressInput ?
                      <div className="d-flex justify-content-end mt-md-3 mt-lg-3">
                        <button
                          onClick={ScheduleService}
                          to="#"
                          className="py-3 d-flex align-items-center btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                          // d-md-none d-lg-none
                          type="submit"
                        >
                          <div className="d-flex justify-content-center text-white offset-md-3">
                            Click here to confirm order
                          </div>
                          <div
                            className="d-flex justify-content-end"
                            style={{ fontSize: "18px", color: "#fff", marginLeft:"20%" }}>₹ {total_pay}</div>
                        </button>
                      </div>

                      :

                      null
                  }



                  {/* <div className="d-flex justify-content-end mt-md-3 mt-lg-3">
                    {ShowSchedule ?
                      <button
                        onClick={ScheduleService}
                        to="#"
                        className="btn btn-success btn-block btn-lg-block btn-md-block schedule-pick-up-btn p-2"
                        // d-md-none d-lg-none
                        type="submit"
                        data-toggle="collapse"
                        data-target="#collapsefour"
                        aria-expanded="true"
                        aria-controls="collapsefour"
                      >
                        Total Submit {total_pay}

                      </button> : null}
                  </div> */}
                  {/* <button className="">Submit Total</button> */}
                </div>

                <div className="col-lg-4 pl-0 mb-3">
                  <SideTotalBill deliveryCharges={deliveryCharges} />
                </div>
              </div>
            </div>
          </section>
        </div>
      );

    default:
      return (
        <div className="App">
          <div className="custom-card form-bg custom-sell-form p-5 mt-2">

          </div>
        </div>
      );
  }

};
