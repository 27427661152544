import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { selectedProduct } from "../../redux/actions/productActions";
// import { Link } from "react-router-dom";
export const OurServices = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const product_category = useSelector((state) => state.allProducts.products);
  const RenderProductCards = product_category?.map((Item) => {
    const {
      product_category_id,
      product_category_image,
      product_category_name,
    } = Item;

    const SelectedCategory = (product_category) => {
      dispatch(selectedProduct(product_category));
      history.push("/product_list");
    };

    return (
      <div className="col-6 col-md-3 col-lg-3 mb-3" key={product_category_id}>
        <div
          style={{ cursor: "pointer" }}
          className="card h-100 hover-card-service"
          onClick={() => {
            SelectedCategory(product_category_id);
          }}
        >
          <div className="row card-body justify-content-center align-items-center">
            <div className="col-md-6 col-lg-6 col-12 text-center">
              <img
                alt="not found"
                src={product_category_image}
                className="img-responsive"
                style={{ width: 100, height: 75 }}
              />
            </div>
            <div className="col-md-6 col-lg-6 col-12 text-center">
              <h6 className="card-text mt-2" style={{ fontSize: "14px" }}>{product_category_name}</h6>
            </div>
          </div>
        </div>
      </div>
    );
  });
  return (
    <div style={{ backgroundColor: "#e8f0f6" }}>
      <div className="container py-4">
        <h3 className="font-weight-bold text-dark text-center">
          Our Services
        </h3>
        {/* <h5 className="text-center text-uppercase text-danger">Our Services</h5> */}
        <p className="text-center">
          Doorstep - Pickup & Drop
        </p>
        <div className="row mt-4 d-flex">
          {RenderProductCards}
        </div>
      </div>
    </div>
  );
};
