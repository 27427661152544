import axios from "axios";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { API_URL } from "../apis/fakeStoreApi";

import {
  addCart,
  removeCart,
  fetchProductList,
} from "../redux/actions/productActions";
import { ProductPopUp } from "./product-pop-up";

export const ProductList = () => {
  const SelectedProductCategory = useSelector((state) => state.product.product);
  const CartItems = useSelector((state) => state.cartItems.cartProducts);
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  const products = useSelector((state) => state.allProducts.products);
  const [CategoryDetails, setCategoryDetails] = useState({});
  const SelectedCategory = useSelector(
    (state) => state.productList.productCategoryList
  );

  const [PopUpItem, setPopUpItem] = useState({});

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProductList(SelectedProductCategory, carSelected.CarSelected));
    setCategoryDetails(products.filter(function (item) {
      return item.product_category_id === SelectedProductCategory;
    }));

  }, [dispatch, SelectedProductCategory, carSelected.CarSelected, products]);

  useEffect(() => {
    localStorage.setItem("cartItems", JSON.stringify(CartItems));
    var url = API_URL + "cart_items";
    var formData = new FormData();
    formData.append("CartItems", JSON.stringify(CartItems));
    if (localStorage.getItem("user_phone")) {
      formData.append("user", localStorage.getItem("user_phone"));
    }

    const config = {
      headers: { "content-type": "multipart/form-data" },
    };
    axios.post(url, formData, config)
  }, [CartItems]);

  const addItemList = (Item) => {
    dispatch(addCart(Item));
  };
  const removeItemList = (Item) => {
    dispatch(removeCart(Item));
  };

  const renderProductList = SelectedCategory.map((Item, index) => {
    const {
      product_id,
      product_name,
      product_price,
      product_discount,
      product_img,
      car_model,
    } = Item;
    const length = CartItems?.find((o) => o.product_id === Item.product_id);
    let show = false;
    if (length === undefined) {
      show = true;
    }
    return (
      <>
        {car_model === carSelected.CarSelected ? (
          <div className="col-sm-12 col-md-12 mb-2" key={product_id}>
            <div
              style={{ height: "100%" }}
              className="
                          list-card
                          h-100
                          rounded
                          overflow-hidden
                          position-relative
                        "
              onClick={() => {
                setPopUpItem(Item);
              }}>
              <div className="card list-card-image mt-md-0 mt-lg-0" style={{ marginTop: index === 0 ? '5px' : null }}>
                {/* <a href="#" className="text-dark"> */}
                <div className="align-items-center mt-md-0 px-3 pt-3 mt-lg-0">
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <p
                      className="text-left mb-0 para-text"
                      data-toggle="modal"
                      data-target=".product-pop-up">
                      {product_name}
                    </p>
                    <div className="member-plan">
                      <span className="badge m-0 badge-danger" style={{ zIndex: 1100 }}>
                        {product_discount}% OFF
                      </span>
                    </div>
                  </div>

                  <div className="row align-items-center">
                    <div className="col-8 col-md-9 col-lg-9">
                      {CategoryDetails[0]?.tag_1 ?
                        <ul className="pl-3 mb-0 ul-text">
                          <li>
                            {CategoryDetails[0].tag_1}
                          </li>
                          <li>
                            {CategoryDetails[0].tag_2}
                          </li>
                        </ul> : null}
                    </div>
                    <div className="col-4 col-md-3 col-lg-3">
                      <img
                        src={product_img}
                        className="img-fluid item-img mb-1"
                        alt={product_name}
                        style={{ height: "100px", width: "100px" }}
                        data-toggle="modal"
                        data-target=".product-pop-up"
                      />
                    </div>
                  </div>
                </div>
                <div className="text-center mb-0">
                  <div className="px-3 d-flex align-items-center justify-content-between mb-0">
                    <p className="price text-left mb-1 text-success">
                      <span className="deleted-price">
                        <del>
                          <small className="small-font ul-text" style={{ fontSize: "14px" }}>₹{parseInt(product_price)}</small>
                        </del>
                      </span><br />
                      ₹
                      <span className="text-success" style={{ fontSize: "14px", fontWeight: "bold", fontFamily: "poppins" }}>{parseInt(
                        parseInt(product_price) -
                        (parseInt(product_discount) *
                          parseInt(product_price)) /
                        100
                      )}{" "}</span>
                    </p>
                    <div className="text-right text-center-sm">
                      {show ? (
                        <button
                          className="btn btn-success mr-2 btn-cart"
                          onClick={() => addItemList(Item)}>
                          Add to Cart
                        </button>
                      ) : (
                        <button
                          className="btn btn-danger btn-cart mr-2"
                          onClick={() => removeItemList(Item)}>
                          Remove
                        </button>
                      )}
                      <button
                        className="btn custom-btn-warning btn-cart"
                        data-toggle="modal"
                        data-target=".product-pop-up"
                      >
                        Details
                      </button>
                    </div>
                  </div>
                  <hr className="my-1" />
                  <div className="d-flex justify-content-start py-2 px-2">
                    <div style={{ fontSize: "10px" }}>
                      <i className="far fa-clock icon-color"></i>
                      <span className="ul-text"> 45 Mins Service </span>
                    </div>
                    <div style={{ marginLeft: "10px", fontSize: "10px" }}>
                      <i className="fas fa-house-user icon-color"></i>
                      <span className="ul-text"> Available at Door Step </span>
                    </div>
                  </div>
                </div>
                {/* </a> */}
              </div>
            </div>
          </div>
        ) : null}
      </>
    );
  });

  return (
    <div className="row mx-1">
      <ProductPopUp data={PopUpItem} />

      {SelectedCategory.length !== 0 ? (
        renderProductList
      ) : (
        <div className="w-100 text-center">
          <img src="./assets/nodata.svg" className="img-fluid w-50" />
          <h3>
            No data found
          </h3>
        </div>
      )}
    </div>
  );
};
