export const Steps = () => {
  return (
    <section className="mb-4" style={{ backgroundColor: "#fff" }}>
      <div className="container">
        <div className="row d-flex justify-content-between align-items-center py-4">
          <div className="col-md-8">
            <span className="my-4 mb-md-4 text-dark" style={{fontSize: "35px"}}>
              4 Step Process
            </span>
            {/* <h4 className="mb-5 text-danger"> </h4> */}
            <div className="my-3">
              <div className="row d-flex align-items-center">
                <span style={{
                  zIndex: "1", fontSize: "35px", color: "#000"
                }}
                  className="text-center pl-2">
                  1
                </span>
                <span
                  style={{
                    width: "35px",
                    height: "15px",
                    background: "#e3181b",
                    position: "relative",
                    top: "14px",
                    left: "-26px"
                  }}
                ></span>

                <div className="col-md-10">
                  <h5 className="mb-0 text-dark">Select your Car Make and Model</h5>
                  <p className="mb-0 text-dark">
                    Select your car from our wide range of car make catalogue
                  </p>
                </div>
              </div>
            </div>
            <div className="my-3">
              <div className="row d-flex align-items-center">
                <span style={{
                  zIndex: "1", fontSize: "35px", color: "#000"
                }}
                  className="text-center">
                  2
                </span>
                <span
                  style={{
                    width: "35px",
                    height: "15px",
                    background: "#e3181b",
                    position: "relative",
                    top: "14px",
                    left: "-26px"
                  }}
                ></span>


                <div className="col-md-10">
                  <h5 className="mb-0 text-dark">Select the Service you are looking for</h5>
                  <p className="mb-0 text-dark">
                    Pick your preferred service for car, at affordable prices.
                  </p>
                </div>
              </div>
            </div>
            <div className="my-3">
              <div className="row d-flex align-items-center">
                <span style={{
                  zIndex: "1", fontSize: "35px", color: "#000"
                }}
                  className="text-center">
                  3
                </span>
                <span
                  style={{
                    width: "35px",
                    height: "15px",
                    background: "#e3181b",
                    position: "relative",
                    top: "14px",
                    left: "-26px"
                  }}
                ></span>
                <div className="col-md-10">
                  <h5 className="mb-0 text-dark">Get your Car Repaired at Doorstep/Garage </h5>
                  <p className="mb-0 text-dark">
                    Select where you want your car to get repaired
                  </p>
                </div>
              </div>
            </div>
            <div className="my-3">
              <div className="row d-flex align-items-center">
                <span style={{
                  zIndex: "1", fontSize: "35px", color: "#000"
                }}
                  className="text-center">
                  4
                </span>
                <span
                  style={{
                    width: "35px",
                    height: "14px",
                    background: "#e3181b",
                    position: "relative",
                    top: "12px",
                    left: "-26px"
                  }}
                ></span>

        

              <div className="col-md-10">
                <h5 className="mb-0 text-dark">Pay Online & Feedback</h5>
                <p className="mb-0 text-dark">
                  Go cash less by pay paying online and submit your feedback.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-4 text-center text-center">
          <img
            className="img-fluid"
            src="./assets/img/car-repair.png"
            alt="not found"
            style={{ height: 312 }}
          />
        </div>
      </div>
    </div>
    </section >
  );
};
