import { combineReducers } from "redux";

import {
  productReducer,
  selectedProductReducer,
  selectedCarReducer,
  selectedProductCategory,
  cartItemsReducer,
  carDataReducer,
  userReducer,
  UserNo,
} from "./productReducer";

const reducers = combineReducers({
  allProducts: productReducer,
  product: selectedProductReducer,
  carSelected: selectedCarReducer,
  productList: selectedProductCategory,
  cartItems: cartItemsReducer,
  carData: carDataReducer,
  user: userReducer,
  phone: UserNo,
});

export default reducers;
