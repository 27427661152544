import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Redirect, useHistory } from "react-router";
import fakeStoreApi, { API_URL } from "../apis/fakeStoreApi";
import { Link } from "react-router-dom";


export const DeliveryComponent = (props) => {
  const ServiceTypeOption = props.ServiceTypeOption;
  const user = useSelector((state) => state.user.payload);
  const CartItems = useSelector((state) => state.cartItems.cartProducts);
  const [AvaliableDate, setAvaliableDate] = useState([])
  const [redirect, setredirect] = useState(false);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    async function fetchMySlot() {
      var jsonSlotData = {};
      let response = await fakeStoreApi.get("/avaliable_slots");
      response?.data.map((item) => {
        const { slot_date, slot_time } = item;
        jsonSlotData[slot_date] = {
          name: slot_date,
          data: {
            ...jsonSlotData[slot_date]?.data,
            [slot_time]: slot_time,
          },
        };
        return <></>;
      });
      setAvaliableDate(jsonSlotData);

    }
    fetchMySlot();
  }, []);


  const RenderDates = (Items) => {
    return Object.keys(Items).map(function (item, index) {
      const d = new Date(item);
      return (
        <div
          className={props.SelectedDate === item ? "active-date-slots mr-2 mb-2 cursor-pointer text-center" : " mb-2 text-center cursor-pointer inactive-date-slots mr-2"}
          // "active-date-slots"
          key={index}
          onClick={() => {
            props.hadleInputOnClickDate(d.getFullYear() + "-" + ("0" + (d.getMonth() + 1)).slice(-2) +
              "-" + ("0" + d.getDate()).slice(-2))
          }
          }
        >
          {d.getDate()} {days[d.getDay()]} {months[d.getMonth()]}
        </div>
      );
    });
  };

  const RenderTime = (Items) => {
    return Object.keys(Items.data).map(function (item, index) {
      return (
        <div key={index} className={props.SelectedTime === item ? "active-time-slots mr-2 cursor-pointer" : "inactive-time-slots mr-2 cursor-pointer"}
          onClick={() => {
            props.hadleInputOnClickTime(item);
          }}
        >
          {item}
        </div>
      );
    });
  };

  const loopTime = {
    data: {
      "08:30 AM to 10:15 AM": "08:30 AM to 10:15 AM",
      "11:00 AM to 12:45 PM": "11:00 Am to 12:45 PM",
      "02:00 PM to 03:45 PM": "02:00 PM to 03:45 PM",
      "04:30 PM to 06:15 PM": "04:30 Pm to 06:15 PM",
    },
  };

  // const RenderTime = loopTime.map((data, index) => {
  //   return (
  //     <option key={index} value={index + 1}>
  //       {data}
  //     </option>
  //   );
  // });

  return (
    <>
      {redirect ? <Redirect to="/order_placed" /> : null}
      <div className="bg-white border-0 shadow-sm overflow-hidden mt-md-0 mt-lg-0 mt-2">
        <div className="card-header bg-white border-0 p-0" id="headingthree">
          <h2 className="mb-0 d-flex justify-content-between align-items-center">
            <button
              className="btn d-flex align-items-center bg-white btn-block text-left btn-lg h5 px-3 py-4 m-0"
              type="button"
              // data-toggle="collapse"
              // data-target="#collapsethree"
              // aria-expanded="true"
              // aria-controls="collapsethree"
              >
              <span className="c-number">4</span>
              {ServiceTypeOption === "walkin" || ServiceTypeOption === "pickupdrop" ? "Place Order" : "Pick Up Time"}
            </button>
            {/* <i className="fas fa-chevron-down mr-4" style={{ fontSize: "15px" }}></i> */}
          </h2>
        </div>
        <div>
          <div className="card-body p-0 border-top">
            <form
            // onSubmit={ScheduleService}
            >
              {ServiceTypeOption !== "walkin" && ServiceTypeOption !== "pickupdrop" ? (
                <div className="osahan-order_address">
                  <div className="row mx-2 mt-3">
                    <div className="col-sm-12 col-md-12 col-lg-12 custom-control">
                      <div className="slots-text mb-2">Date Slots</div>
                      <div
                        style={{ overflowX: "scroll", width: "100%" }}
                        id="date"
                        name="date"
                        className="d-flex"
                        required>
                        {RenderDates(AvaliableDate)}
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-12 custom-control">
                      <div className="slots-text mb-2"> Time Slots </div>
                      <div
                        id="time"
                        name="time"
                        className="d-flex"
                      >
                        {RenderTime(
                          AvaliableDate[props.SelectedDate]
                            ? AvaliableDate[props.SelectedDate]
                            : loopTime
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              ) : <>
                <p className="mx-3 my-3">Our service executive will get in touch with you shortly </p>
              </>}
              <div className="ml-3 mt-3 row d-flex align-item-center">
                <input
                  type="checkbox"
                  defaultChecked
                  style={{ height: 25 }}
                  onChange={(e) => props.setShowSchedule(e.target.checked)}
                />
                <p className="mx-1 mt-1 mb-0">
                  Pay After Service
                </p>
              </div>

              <div className="p-3 d-flex justify-content-end">
                {/* {ShowSchedule ?
                  <Link
                    to="#"
                    className="btn btn-success mt-3"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapsethree"
                    aria-expanded="true"
                    aria-controls="collapsetwo"
                  >
                    {ServiceTypeOption === "walkin" || ServiceTypeOption === "pickupdrop"
                      ? "Place Order"
                      : "Schedule Pick Up"}
                  </Link> : null} */}
                {/* <Link
                  to="#"
                  className="btn btn-success mt-3"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapsetwo"
                  aria-expanded="true"
                  aria-controls="collapsetwo">
                  Continue
                </Link> */}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
