import "./App.css";
import React, { Component, useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Header } from "./layouts/header";
import { Home } from "./pages/home";
import { ProductListPage } from "./pages/product-list-page";
import { useDispatch } from "react-redux";

import {
  fetchLocalCart,
  fetchLocalCar,
  fetchCarDetails,
  fetchProducts,
  fetchUserIn,
  LocalUserNo,
} from "./redux/actions/productActions";
import { Footer } from "./layouts/footer";
import { CartPage } from "./pages/cart-page";
import { PopUp } from "./containers/pop-up";
import { OrderPlaced } from "./pages/order-placed";
import { UserOrders } from "./pages/user-orders";
import { SideSignInComponent } from "./containers/side-signin-component";
import { PrivacyPolicy } from "./pages/privacy-policy";
import { TermsCondition } from "./pages/terms-conditions";
class App extends Component {
  render() {
    const CallLocalStorage = () => {
      const dispatch = useDispatch();

      const localCart = localStorage.getItem("cartItems");
      const localCar = localStorage.getItem("carSelected");
      const localUser = localStorage.getItem("user");
      const localUserPhone = localStorage.getItem("user_phone");

      useEffect(() => {
        if (localUserPhone && localUserPhone.length > 0) {
          dispatch(LocalUserNo(localUserPhone));
        }
      }, [dispatch, localUserPhone]);

      useEffect(() => {
        if (localUser && localUser.length > 0) {
          dispatch(fetchUserIn(JSON.parse(localUser)));
        }
      }, [dispatch, localUser]);

      useEffect(() => {
        if (localCar && localCar.length > 0) {
          dispatch(fetchLocalCar(JSON.parse(localCar)));
        }
      }, [dispatch, localCar]);

      useEffect(() => {
        if (localCart && localCart.length > 0) {
          dispatch(fetchLocalCart(JSON.parse(localCart)));
        }

      }, [dispatch, localCart]);

      useEffect(() => {
        dispatch(fetchCarDetails());
        dispatch(fetchProducts());
      }, [dispatch]);
      return <></>;
    };
    return (
      <div className="App" >
        <CallLocalStorage />
        <Router>
          {/* <div style={{ marginBottom: 100 }}></div> */}
          <Header />

          <PopUp title="Pick a Car" />
          <SideSignInComponent />

          <Switch>
            <Route path="/" exact style={{ marginTop: 100 }} component={Home} />
            <Route path="/product_list" exact component={ProductListPage} />
            <Route path="/cart_page" exact component={CartPage} />
            <Route path="/order_placed" exact component={OrderPlaced} />
            <Route path="/my_orders" exact component={UserOrders} />
            <Route path="/privacy_policy" exact component={PrivacyPolicy} />
            <Route path="/terms_conditions" exact component={TermsCondition} />
          </Switch>
          <Footer />
        </Router>
      </div>
    );
  }
}

export default App;
