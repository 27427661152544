import {Link} from 'react-router-dom';
export const TermsCondition = () =>{
    return(
        <section className="" style={{marginTop:250}}>
            <div className="container">
                <div className="row d-flex">
                    <div>
                        <h1>
                            Terms and Conditions    
                        </h1>
                        <p className="text-bold">
                            PLEASE READ THESE TERMS OF SERVICE CAREFULLY BEFORE Using THE Repair Bro Website.
                        </p>
                    </div>
                </div>
                <div className="row d-flex">
                    <p>
                    By using, accessing, and/or using this Website owned by <Link to="/">www.metrepersecod.com</Link>. ("Metre Per
                    Second") you signify that you have read, understand, and agree to be bound by all of the terms
                    and conditions as set forth in this Terms of Use Agreement ("Agreement"). Repair Bro
                    may change the terms of the Agreement at any time and without notice, effective upon the
                    posting of the revised Agreement. Your continued use of the Website shall be considered your
                    acceptance of the revised Agreement. You must have the most current version of the Website
                    to ensure that it is working properly. It is your responsibility to periodically check the Website
                    and/or our website at www.metrepersecond.com to determine if you have the most current
                    version of the Website. If you do not agree to this Agreement, please do not Use the Website.
                    </p>
                    
                    <h3>
                        No Warranties
                    </h3>

                    <p>
                        The Website is provided subject to all of the terms set forth in the Mobile Website
                        Documentation. Repair Bro does not warrant that:
                    </p>
                    <ul>
                        <li>
                            Its Website will be constantly available, or available at all;
                        </li>
                        <li>
                            The information on this Website is complete, true, accurate;
                        </li>
                        <li>
                            Your opt-out choices will be successfully executed in all cases;
                        </li>
                        <li>
                            The Website is free of defects or errors.
                        </li>
                    </ul>
                    <p>
                        The Using and/or use of the Website is entirely at your own risk and in no event shall Metre Per
                        Second be liable (whether under the law of contracts, torts, or otherwise) for any direct,
                        indirect, incidental, consequential, special, exemplary, punitive or any other monetary or
                        another damages, fees, fines, penalties or liabilities (collectively "damages") whatsoever arising
                        out of or relating to this Website. your sole and exclusive remedy for dissatisfaction with the
                        Website is to stop using the Website. You agree to defend, indemnify and hold Metre Per
                        Second, and its officers, directors, employees, representatives, and agents harmless from and
                        against any claims, actions, demands, liabilities, judgments, and settlements, including without
                        limitations, reasonable legal fees resulting from or alleged to result from your use of this
                        Website.
                    </p>
                    <p>
                    Unenforceable Provisions<br />
                        If any provision of this Agreement is or is found to be, unenforceable under applicable law, that
                        will not affect the enforceability of the other provisions of this Agreement.
                    </p>
                    <h3>
                        Ownership of Intellectual Property and Limited License
                    </h3>
                    <p>
                        All intellectual property to this Website, including all Website material, is protected by
                        copyright, trademark, or patent laws, and is owned exclusively by Repair Bro.
                        Intellectual property, includes, but is not limited to, computer or software code, scripts, design
                        elements, graphics, interactive features, artwork, text communication, and any other content
                        that may be found on or on the Website. All trademarks, service marks, and trade names are
                        owned, registered, and/or licensed by Repair Bro. Repair Bro grants to you a
                        worldwide, non-exclusive, royalty-free, revocable license to use this Website to a mobile
                        device via a web browser; use this Website as herein set forth; copy and store this Website and
                        the material on this Website in your web browser cache memory, and print pages from this
                        Website for your own personal and non-commercial use. Repair Bro does not grant you
                        any other rights whatsoever in relation to this Website or the material on this Website. All
                        other rights are expressly reserved by Repair Bro.


                    </p>
                    <h3>
                        Use and Prohibited Use Restrictions
                    </h3>
                    <p>
                        You may use the Website only for your own personal, non-commercial use. You are strictly
                        prohibited from and agree that you will not, adapt, edit, change, modify, transform, publish,
                        republish, distribute, or redistribute this Website or the material on this Website (in any form or
                        media) without Repair Bro's prior written consent. You agree not to use any automated
                        data collection methods, data mining, robots, or scraping or any data gathering methods of any
                        kind on this Website.
                    </p>
                    <h3>
                        Enforcement of Copyright and Protection of Intellectual Property
                    </h3>
                    <p>
                        If Repair Bro discovers that you have used its copyrighted or other protected materials in
                        contravention of the terms of the license above, Repair Bro may bring legal proceedings
                        against you, seeking monetary damages and an injunction against you. You could also be
                        ordered to pay legal fees and costs. If you become aware of any use of Repair Bro's
                        copyright or protected materials that contravenes or may contravene the terms of the license
                        above, immediately report this by email to privacy@.Repair Bro.com or by first-class
                        mail to Repair Bro, Hyderabad care of Legal Department
                    </p>
                    <h3>
                        Governing Law and Dispute Resolution
                    </h3>
                    <p>
                        This Agreement shall be governed by and shall be construed in accordance with the laws of
                        Karnataka state, excluding its choice of law rules. All disputes relating to this Agreement shall
                        be settled in the courts located in Hyderabad, Telangana. The parties submit to personal
                        jurisdiction within Telangana
                    </p>
                </div>
            </div>
        </section>
    );
}