import { useState } from "react";
import { Link } from "react-router-dom";

export const ServiceType = (props) => {

  const [showContinue, setshowContinue] = useState(false);
  const show = () => {
    setshowContinue(true);
  };
  return (
    <>
      <div className="bg-white border-0 shadow-sm overflow-hidden mt-1 mb-5 mb-md-0 mb-lg-0 mt-lg-0 mt-md-0">
        <div className="card-header bg-white border-0 p-0" id="headingthree">
          <h2 className="mb-0 d-flex justify-content-between align-items-center">
            <button
              className="btn d-flex align-items-center bg-white btn-block text-left btn-lg h5 px-3 py-4 m-0"
              type="button"
            >
              <span className="c-number">2</span> Service Type
            </button>
            {/* <i className="fas fa-chevron-down mr-4" style={{ fontSize: "15px" }}></i> */}
          </h2>
        </div>
        <div>
          <div className="card-body p-0 border-top">
            <form>
              <div className="osahan-order_address">
                <div className="mx-4 my-3">
                  <h5>Service Type</h5>
                  <div className="form-check">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="selectservice"
                      id="doorstep"
                      value="doorstep"
                      onChange={(e) => {
                        props.onServiceChange(e);
                        show();
                      }}
                    />
                    <p className="form-check-label" htmlFor="exampleRadios1">
                      Door Step{" "}
                      <span>
                        <small className="text-muted ml-3">
                          {/* (*Additionl 500Rs will be charged) */}
                        </small>
                      </span>
                    </p>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="selectservice"
                      id="pickupdrop"
                      value="pickupdrop"
                      onChange={(e) => {
                        props.onServiceChange(e);
                        show();
                      }}
                    />
                    <p className="form-check-label" htmlFor="exampleRadios1">
                      Pick Up and Drop
                      <span>
                        <small className="text-muted ml-3">
                          {/* (*Additionl 500Rs will be charged) */}
                        </small>
                      </span>
                    </p>
                  </div>
                  <div className="form-check">
                    <input
                      className="form-check-input "
                      type="radio"
                      name="selectservice"
                      id="walkin"
                      value="walkin"
                      onChange={(e) => {
                        props.onServiceChange(e);
                        show();
                      }}
                    />
                    <p className="form-check-label" htmlFor="exampleRadios1">
                      Walk In
                    </p>
                  </div>
                  {/* {showContinue ? (
                    <div className="d-flex justify-content-end">
                      <Link
                        to="#"
                        className="btn btn-success mt-3"
                        type="button"
                        data-toggle="collapse"
                        data-target="#collapsetwo"
                        aria-expanded="true"
                        aria-controls="collapsetwo">
                        Continue
                      </Link>
                    </div>
                  ) : null} */}
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
