import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import {
  fetchProducts,
  selectedProduct,
} from "../redux/actions/productActions";

export const SideProductCategory = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchProducts());
  }, [dispatch]);
  const products = useSelector((state) => state.allProducts.products);

  const SelectedCategory = (product_category) => {
    dispatch(selectedProduct(product_category));
  };

  useEffect(() => {
    SelectedCategory(products[0]?.product_category_id); // eslint-disable-next-line
  }, [products]);

  const SelectedProductCategory = useSelector((state) => state.product.product);

  const renderList = products.map((product) => {
    const {
      product_category_id,
      product_category_name,
      product_category_image,
    } = product;
    return (
      <>
        <div
          key={product_category_id}
          onClick={() => {
            SelectedCategory(product_category_id);
          }}
          className="col-md-5 mb-2 text-center pr-0">
          <div
            className={`card ${SelectedProductCategory === product_category_id
              ? "activeSideItem"
              : null
              }`}
            style={{ width: "100%", height: "100%" }}>
            <div className="card-body" style={{ padding: "0.5rem", cursor: "pointer" }}>
              <img
                className="card-img-top mx-auto img-fluid  text-center"
                style={{ width: 45, height: 45 }}
                src={product_category_image}
                alt="Card Cap"
              />
              <p className="card-text">
                <strong>{product_category_name}</strong>
              </p>
            </div>
            {/* <div className="row card-body py-2">
            <div className="col-sm-4">
              
            </div>
            <div className="col-sm-8">
              
            </div>
          </div> */}
          </div>
        </div>
      </>
    );
  });

  const renderListSm = products.map((product, index) => {
    const { product_category_id, product_category_name } = product;
    return (
      <>
        <option value={product_category_id} key={index}>
          {product_category_name}
        </option>
      </>
    );
  });

  return (
    <>
 
      <div
        className="row d-none d-md-flex justify-content-center sticky_sidebar_custom">
        {renderList}
      </div>
      <div style={{ backgroundColor: "#808080", position:"absolute", top:"-96px" }}>
        <div
          className="row d-flex d-sm-none justify-content-center sticky_sidebar_fixed mt-md-0 mt-lg-0 p-md-3 p-lg-3">
          <select
            className="mx-2 form-control form-control-lg"
            onChange={(e) => {
              SelectedCategory(e.target.value);
            }}>
            {renderListSm}
          </select>
        </div>
      </div>
    </>
  );
};
