import React from "react";
import { Link } from "react-router-dom";
// import { SideSignInComponent } from "../containers/side-signin-component";
export const Footer = () => {
  return (
    <footer className="section-footer border-top bg-white mt-auto">
      <section className="footer-bottom py-4 fixedFooter">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-12 text-center row justify-content-between">
              <span className="pr-2">© 2023 Repair Bro</span>
              <div>
                <span className="pr-4">
                  <Link to="/privacy_policy" className="text-dark">
                    Privacy
                  </Link>
                </span>
                <span className="pr-2">
                  <Link to="/terms_conditions" className="text-dark">
                    Terms & Conditions
                  </Link>
                </span>
              </div>

            </div>
          </div>
        </div>
      </section>
    </footer>
  );
};
