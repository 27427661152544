import { ProductList } from "../containers/product-list";
import { SideCart } from "../containers/side-cart";
import { SideProductCategory } from "../containers/side-product-category";
import $ from "jquery";
import { useSelector } from "react-redux";
import { useEffect } from "react";

export const ProductListPage = () => {
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  //console.log(carSelected.isCarSelected, "Car Daata");
  useEffect(() => {
    if (!carSelected.isCarSelected) {
      $("#SelectCar").addClass("show");
      $("#SelectCar").attr("style", "display: block");
      $("body").append("<div class='modal-backdrop fade show'></div>");
    } else {
      $("#SelectCar").addClass("hidden");
      $("#SelectCar").attr("style", "display: none");
      $("body").removeClass("modal-open");
      $("body").removeAttr("style");
      $(".modal-backdrop").remove();
    }
  }, [carSelected.isCarSelected]);
  return (
    <>
      {/* <div className="m-0">
      &nbsp;
    </div> */}
      <div className="container px-0" style={{ overflowX: "hidden" }}>
        <h5 className="container ml-md-3 mb-0 my-md-3 mt-5 mt-md-0 mt-lg-0 pt-4 pl-md-3 pl-2">Services</h5>
        <div className="row d-flex mx-md-0 mx-lg-0">
          <div className="col-sm-12 col-lg-3 col-md-3 pr-0">
            <SideProductCategory />
          </div>
          <div className="col-sm-12 col-lg-6 col-md-6 justify-content-center pl-0" style={{ overflowY: "scroll", height: "860px" }}>
            <ProductList />
          </div>
          <div className="col-sm-12 col-lg-3 col-md-3 mx-1 mx-md-0 mx-lg-0">
            <SideCart />
          </div>
        </div>
      </div>
    </>
  );
};
