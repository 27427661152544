export const OurPatners = () => {
  const imgurl = [
    {
      url: "./assets/img/bro4u.png"
      // url: "https://img1.wsimg.com/isteam/ip/a0ecb5af-65ad-4934-8594-74a22a5d551c/download%20(2).png/:/rs=h:100,cg:true,m",
    },
    {
      url: "https://img1.wsimg.com/isteam/ip/a0ecb5af-65ad-4934-8594-74a22a5d551c/Uber-Logo.wine.png/:/cr=t:0%25,l:0%25,w:100%25,h:100%25/rs=w:150,h:100,cg:true",
    },
    {
      url: "./assets/img/ola (3).png"
      // url: "https://img1.wsimg.com/isteam/ip/a0ecb5af-65ad-4934-8594-74a22a5d551c/e93a6ead3f6784c21a6620d1102ea88f.jpg/:/rs=w:133,h:100,cg:true,m/cr=w:133,h:100",
    },
    {
      url:"./assets/img/pitstop.png"
      // url: "https://img1.wsimg.com/isteam/ip/a0ecb5af-65ad-4934-8594-74a22a5d551c/Pitstop-Logo-01-scaled-e1574432977309.jpg/:/rs=w:100,h:100,cg:true,m/cr=w:100,h:100",
    },
    {
      url: "https://img1.wsimg.com/isteam/ip/a0ecb5af-65ad-4934-8594-74a22a5d551c/allianz-global-assistance-logo.png/:/rs=w:160,h:100,cg:true,m/cr=w:160,h:100",
    },
    {
      url: "./assets/img/uc.png",
    },
  ];
  const RenderPatners = imgurl.map((item) => {
    return (
      <div className="col-md-2 col-mlg-2  text-center" key={item.url}>
        <div className="card border-0 partnersList">
          <img
            className="rounded"
            src={item.url}
            alt="not found"
            style={{ width: 100, height: 100 }}
          />
        </div>
      </div>
    );
  });
  return (
    <>
      <section className="py-4 osahan-main-body" style={{ backgroundColor: "#fff" }}>
        <div className="container">
          <h3 className="mb-4 font-weight-bold text-dark text-left">
            Our Partners
          </h3>
          {/* <h5 className="text-center text-uppercase text-danger">
            Our Partners
          </h5> */}
          <div
            className="d-flex my-3 partner-scroll-bar">{RenderPatners}</div>
        </div>
      </section>
    </>
  );
};
