import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { removeCart } from "../redux/actions/productActions";
import "./custom_css.css"; 

export const SideCart = () => {
  const CartItems = useSelector((state) => state.cartItems.cartProducts);
  const carSelected = useSelector((state) => state.carSelected.carSelected);
  const dispatch = useDispatch();

  const removeItemList = (Item) => {
    dispatch(removeCart(Item));
  };
  let total_pay = 0;
  let total_discount = 0;
  const renderCartItems = CartItems?.map((Item) => {
    const {
      product_id,
      product_name,
      product_price,
      sub_category_title,
      product_discount,
      sub_price,
    } = Item;
    total_discount += parseInt(
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );
    total_pay += parseInt(
      parseInt(product_price) -
      (parseInt(product_discount) * parseInt(product_price)) / 100
    );

    return (
      <div
        className="row d-flex my-1 justify-content-between border-bottom align-items-center"
        key={product_id}>
        <div className="col-sm-6 align-items-center">
          <p style={{ fontSize: 11 }}>
            {product_name}{" "}
            <span className="small">
              <br />
              {sub_category_title
                ? sub_category_title + "- ₹" + sub_price
                : null}
            </span>
          </p>
        </div>
        <div className="col-sm-6">
          <div className="ml-auto float-right d-flex align-items-center">
            <p className="text-muted ml-5 small mb-0">
              <del>
                ₹
                {parseInt(product_price) +
                  (sub_price ? parseInt(sub_price) : 0)}
              </del>
            </p>
            <p className="ml-1 text-success mb-0">
              ₹
              {parseInt(
                parseInt(product_price) -
                (parseInt(product_discount) * parseInt(product_price)) / 100
              )}
            </p>
            <h4 onClick={() => removeItemList(Item)} className="ml-3 ">
              <i
                className="far fa-times-circle text-danger"
                style={{ fontSize: 17 }}></i>
            </h4>
          </div>
        </div>
        <hr />
      </div>
    );
  });

  return (
    <div className="pr-md-3 pr-lg-3 sticky_sidebar sticky_sidebar_left">
      <div className="cart-bg-color rounded overflow-hidden shadow-sm mb-3 checkout-sidebar mr-2 mr-md-0 mr-lg-0">
        <div className="d-flex align-items-center osahan-cart-item-profile border-bottom p-3">
          <img
            alt="car"
            src={carSelected.CarImage}
            className="mr-3 rounded-circle img-fluid"
            style={{ height: 55 }}
          />
          <div className="d-flex flex-column">
            <h6 className="mb-1">{carSelected.Company}</h6>
            <p className="mb-0 text-muted">
              <i className="feather-map-pin"></i> {carSelected.Model},{" "}
              {carSelected.Fuel}
            </p>
          </div>
        </div>
        <div
          className="bg-white p-3 clearfix"
        // style={{ maxHeight: 100, overflowY: "auto" }}
        >
          <p className="font-weight-bold mb-2">Cart Items</p>
          <div
            style={{
              maxHeight: 150,
              minHeight: 50,
              overflowY: "auto",
              overflowX: "hidden",
            }}>
            {renderCartItems}
          </div>

          <p className="mb-0 text-success text-left mt-3">
            Total Discount
            <span className="float-right text-success mr-4">
              ₹{total_discount}
            </span>
          </p>
        </div>
        <div className="p-3 border-top text-left">
          <h6 className="mb-0">
            TO PAY <span className="float-right text-success font-weight-bold text-white">₹{total_pay}</span>
          </h6>
        </div>
      </div>
      <Link
        to="cart_page"
        className="w-100 sticky_sidebar_footbar pr-4 pr-md-0 pr-lg-0"
        type="button"
        data-toggle="collapse"
        data-target="#collapsetwo"
        aria-expanded="true"
        aria-controls="collapsetwo"
        style={{ marginBottom: 200 }}>
        <div className="rounded  shadow custom-btn-warning d-flex align-items-center p-3 text-dark mb-3 mb-md-0 md-lg-0">
          <div className="more row para-text">
            <p className="m-0 text-white" style={{ fontSize: "16px" }}>₹{total_pay} </p>
          </div>
          <div className="ml-auto">
            <p className="m-0 text-right text-white" style={{ fontSize: "16px" }}>
              Checkout <i className="icofont-simple-right text-white"></i>
            </p>
          </div>
        </div>
      </Link>
    </div>
  );
};
